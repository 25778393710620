'use client';
import { newsletterImg } from '@/assets/images';
import { JoinNewsletter } from '@/services/auth.api';
import { getErrorMessge } from '@/utils/helpers';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import isEmail from 'validator/lib/isEmail';

const NewsLetter = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const joinNwsLetter = async () => {
    try {
      if (!isEmail(email)) {
        toast.error('Please enter a valid email address');
        return;
      }
      setLoading(true);
      await JoinNewsletter(email);
      setEmail('');
      toast.success('Thank you for suscribing to our newsletter');
    } catch (err) {
      setLoading(false);
      const errMessage = getErrorMessge(err);
      toast.error(errMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setError('');
    }, 5000);
  }, [error]);

  return (
    <section>
      <div className="flex flex-col gap-5 items-center justify-center py-24 px-4 md:px-12 lg:px-48 bg-[#FFDBDB] text-center ">
        {/* Newletter image */}
        <div className="relative overflow-hidden w-full max-w-[200px] aspect-square rounded-full">
          <Image
            alt="newsletter"
            src={newsletterImg}
            fill
            className="object-cover"
          />
        </div>

        <h1 className="text-2xl lg:text-3xl font-bold">
          Subscribe to our Newsletter
        </h1>
        <p className="text-sm">
          Be the first to get lucky on our amazing offers
        </p>

        <section className="w-full flex justify-center mt-4">
          <div className="bg-[#FFDBDB] md:bg-white py-4 md:py-1 md:rounded-full p-1 overflow-hidden md:p-2 flex w-full  flex-col md:flex-row md:max-w-[85%] lg:max-w-[80%] 2xl:max-w-[60%]">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full outline-0 px-4 bg-white h-16 md:h-14 rounded-full mb-5 md:mb-0"
              placeholder="Email Address"
            />
            <button
              onClick={joinNwsLetter}
              className=" md:flex text-sm bg-crunchies-red px-8 py-5  rounded-full text-white md:whitespace-nowrap"
            >
              Subscribe Now
            </button>
          </div>
        </section>
        <div>
          {error && (
            <p className="text-red-500 text-sm font-poppinsRegular">{error}</p>
          )}
          {loading && (
            <p className="text-green-500 text-sm font-poppinsRegular">
              Loading...
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default NewsLetter;
