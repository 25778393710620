import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/1-min.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/2-min.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/3-min.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/4-min.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/5-min.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/6-min.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/About_US-min.jpeg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/about-us-min.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/Apple_Store_logo.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/blog-min.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/Bread-min.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/Cakes-min.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/CAREERS-min.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/FANCLUB-min.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/Food-min.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/Google_Play_2016_icon.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/logo-red.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/logo-white.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/mgt/ed-ops.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/mgt/fc.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/mgt/ghgs.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/mgt/haic.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/mgt/hhra.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/mgt/hsm.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/mgt/itm.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/mgt/md.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/MOBILE-min.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/Newsletter-min.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/OFFERS-min.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/Pastries-min.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/Protein-min.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/Sharwama-min.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/global/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/NewsLetter.tsx");
